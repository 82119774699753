import Vue from "vue";
import VueRouter from "vue-router";
// eslint-disable-next-line
import { getCookie, setCookie } from "@/services/cookies";
import YMetric from "@/plugins/YMetric";
import App from "../App.vue";
import store from "../store";

const { VUE_APP_YANDEX_METRIC_ENABLED, VUE_APP_YANDEX_METRIC_ID } = process.env;

Vue.use(VueRouter);

const { VUE_APP_HOST } = process.env;

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const queryToken = to.query?.token ? decodeURI(to.query?.token) : null;
  const storeToken = store.getters["user/token"] ? decodeURI(store.getters["user/token"]) : null;
  const cookieToken = getCookie("token") ? decodeURI(getCookie("token")) : null;

  const token = storeToken || queryToken || cookieToken || null;

  let user = store.getters["user/user"];
  if (token) {
    store.commit("user/SET_TOKEN", token);
    setCookie("token", token);
    if (!user) {
      await store.dispatch("user/GET_USER");

      if (!!VUE_APP_YANDEX_METRIC_ENABLED && VUE_APP_YANDEX_METRIC_ENABLED === "true") {
        YMetric.loadScript(() => {
          user = store.getters["user/user"];
          const ym = new YMetric(
            VUE_APP_YANDEX_METRIC_ID,
            {
              UserID: user.keycloak_id,
            }
            // VUE_APP_YANDEX_METRIC_DOMAINS ? VUE_APP_YANDEX_METRIC_DOMAINS?.split(",") : null
          );
          ym.createYM();
        });
      }
    }
  }
  if (process.env.NODE_ENV === "production") {
    // console.log("token 1", token);
    if (token) {
      // console.log("token 2", token);
      if (to.query?.token) {
        const { query } = to;
        // console.log("token 2", query);
        delete query.token;
        await router.replace({ query }).then(() => {
          next();
        });
      } else {
        next();
      }
    } else {
      window.location = `${VUE_APP_HOST}/auth/redirect`;
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  window[`yaCounter${process.env.VUE_APP_YANDEX_METRIC_ID}`]?.hit();
});

export default router;
