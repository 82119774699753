export default class YMetric {
  config = {
    id: null,
    userParams: {},
    // defer: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    // webvisor: true,
    // trustedDomains: null,
    // trackHash: true,
    // childIframe: true,
  };

  constructor(idMetric, userData = {}) {
    this.config.id = idMetric;
    this.config.userParams = userData;
    // this.config.trustedDomains = trustedDomains;
  }

  static checkConfig() {
    // Checks if config is valid
    if (typeof document === "undefined") {
      throw new Error("[vue-yandex-metrika] Not Document DOM");
    }
    // if (!this.config.id) {
    //   throw new Error("[vue-yandex-metrika] Please enter a Yandex Metrika tracking ID");
    // }
    // if (!config.router && config.env !== "production") {
    //   return console.warn("[vue-yandex-metrika] Router is not passed, autotracking is disabled");
    // }
  }

  static loadScript(callback, scriptSrc = "https://mc.yandex.ru/metrika/tag.js") {
    const head = document.head || document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.async = true;
    script.charset = "utf-8";
    script.src = scriptSrc;

    head.appendChild(script);

    script.onload = callback;
  }

  createYM() {
    // eslint-disable-next-line
    const metric = new Ya.Metrika2(this.config);
    window[`yaCounter${this.config.id}`] = metric;
  }
}
