import API from '@/api';
import { getCookie } from '@/services/cookies';

const { VUE_APP_HOST } = process.env;

export default {
  state: () => ({
    user: null,
    token: null,
    pending: false,
  }),
  mutations: {
    LOGOUT: (state) => {
      state.user = null;
      state.token = null;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_PENDING: (state, pending) => {
      state.pending = pending;
    },
  },
  actions: {
    async GET_USER({ commit }) {
      commit('SET_PENDING', true);
      if (!API.defaults.headers.Authorization && getCookie('token')) {
        API.defaults.headers.Authorization = `Bearer ${getCookie('token')}`;
      }
      await API.get('/api/user')
        .then((response) => {
          commit('SET_USER', response.data.data);
        })
        .catch((errors) => {
          console.log(errors, 'GET_USER');
          if (process.env.NODE_ENV === 'production') {
            window.location = `${VUE_APP_HOST}/auth/redirect`;
          }
        })
        .finally(() => {
          commit('SET_PENDING', false);
        });
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
  },
  namespaced: true,
};
