export default {
  state: () => ({
    applications: [],
    registered: [],
    bootstrap: [],
    openFirstApplication: '',
  }),
  mutations: {
    SET_APPLICATIONS: (state, applications) => {
      state.applications = applications;
    },
    SET_REGISTERED: (state, application) => {
      state.registered = [...state.registered, application];
    },
    SET_BOOTSTRAP: (state, application) => {
      state.bootstrap = [...state.bootstrap, application];
    },
    SET_OPEN_FIRST_APPLICATIONS: (state, openFirstApplication) => {
      state.openFirstApplication = openFirstApplication;
    },
  },
  getters: {
    applications: (state) => state.applications,
    registered: (state) => state.registered,
    bootstrap: (state) => state.bootstrap,
    openFirstApplication: (state) => state.openFirstApplication,
  },
  namespaced: true,
};
