export default {
  state: () => ({
    breadcrumbs: [],
  }),
  mutations: {
    SET_BREADCRUMBS: (state, breadcrumbs) => {
      state.breadcrumbs = breadcrumbs;
    },
  },
  actions: {},
  getters: {
    breadcrumbs: (state) => state.breadcrumbs,
  },
  namespaced: true,
};
