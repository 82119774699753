import menu from "./modules/menu";
import breadcrumbs from "./modules/breadcrumbs";

function asc(a, b) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}

const { VUE_APP_LAYOUT_DASHBOARD_HEADER_TITLE, VUE_APP_LAYOUT_DASHBOARD_HEADER_REGION } =
  process.env;

export default {
  state: () => ({
    title: "",
    subTitle: "",
    isCompact: true,
    isIconChild: false,
    menuOptions: {
      isCompact: false,
      isIconChild: false,
      items: [],
    },
    header: {
      title: VUE_APP_LAYOUT_DASHBOARD_HEADER_TITLE,
      region: VUE_APP_LAYOUT_DASHBOARD_HEADER_REGION,
    },
    pending: true,
  }),
  mutations: {
    SET_MENU_OPTIONS: (state, menuOptions) => {
      state.menuOptions = menuOptions;
    },
    SET_IS_COMPACT: (state, isCompact) => {
      state.isCompact = isCompact;
    },
    SET_IS_ICON_CHILD: (state, isIconChild) => {
      state.isIconChild = isIconChild;
    },
    SET_ITEMS: (state, items) => {
      state.menuOptions.items = items;
    },
    SET_ITEM: (state, item) => {
      const map = state.menuOptions.items.map((unit) => unit.library);
      if (!map.includes(item.library)) {
        state.menuOptions.items = [...state.menuOptions.items, item];
        state.menuOptions.items.sort(asc);
      }
    },
    CHANGE_BULB_ITEM: (state, item) => {
      console.log("CHANGE_BULB_ITEM", state, item);
      const index = state.menuOptions.items.findIndex((el) => el.library === item.library);
      if (index >= 0) {
        state.menuOptions.items[index].bulbOptions = item.bulbOptions;
      }
    },
    SET_TITLE: (state, title) => {
      state.title = title;
    },
    SET_SUB_TITLE: (state, subTitle) => {
      state.subTitle = subTitle;
    },
    SET_PENDING: (state, pending) => {
      state.pending = pending;
    },
  },
  getters: {
    isCompact: (state) => state.isCompact,
    isIconChild: (state) => state.isIconChild,
    menuOptions: (state) => state.menuOptions,
    title: (state) => state.title || "",
    subTitle: (state) => state.subTitle || "",
    headerTitle: (state) => state.header.title || "",
    headerRegion: (state) => state.header.region || "",
    pending: (state) => state.pending,
  },
  modules: {
    breadcrumbs,
    menu,
  },
  namespaced: true,
};
