<template>
  <div id="orchestrator">
    <r-layout-dashboard
      :menu-options="pending ? { items: [] } : menuOptions"
      :is-compact="isCompact"
      :title="title"
      :sub-title="subTitle"
      :isOffsetContent="false"
    >
      <template #header>
        <h1 class="taleggio harakiri--text">{{ headerTitle }}</h1>
        <div class="caprino metropolis--text mt-1">{{ headerRegion }}</div>
      </template>
      <template #footer>
        <IconRosAtom />
      </template>
      <template #actions>
        <div v-if="user" class="user-block">
          <r-button-simple
            size="larishae"
            type="transparent"
            icon="notifications"
            color="harakiri"
          />
          <div class="user-block__popover">
            <r-avatar size="aethelsa" color="nixilei" :title="user.name" />
            <r-popover :clickClose="true" position="right-bottom">
              <template #activator="{ on }">
                <r-button-action v-on="on" :title="user.name" color="harakiri" is-arrow />
              </template>
              <template #content>
                <div class="mt-1 ml-1 mb-1 mr-1">
                  <r-list-item title="Выход" icon="exit" @click.native="logout" />
                </div>
              </template>
            </r-popover>
          </div>
        </div>
      </template>
      <template #navigation>
        <r-bread-crumbs v-if="breadcrumbs.length > 0" :items="breadcrumbs" />
      </template>
      <template #content>
        <div v-if="pending" class="single-spa-pending">
          <r-spinner size="synallis" />
        </div>
        <div v-show="!pending" id="modules" />
      </template>
    </r-layout-dashboard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  registerApplication,
  setBootstrapMaxTime,
  setMountMaxTime,
  setUnloadMaxTime,
  setUnmountMaxTime,
  start,
} from "single-spa";
import PubSub from "pubsub-js";
import { getCookie, removeCookie } from "@/services/cookies";
import store from "@/store";
import IconRosAtom from "@/components/IconRosAtom.vue";
import axios from "axios";

const { VUE_APP_HOST } = process.env;

PubSub.subscribe("orchestrator:menu", (message, data) => {
  store.commit("layout/SET_ITEM", data);
  store.commit("applications/SET_BOOTSTRAP", data);
});

PubSub.subscribe("orchestrator:menu:change:bulb", (message, data) => {
  store.commit("layout/CHANGE_BULB_ITEM", data);
});

PubSub.subscribe("orchestrator:layout:breadcrumbs", (message, data) => {
  store.commit("layout/breadcrumbs/SET_BREADCRUMBS", data);
});

PubSub.subscribe("orchestrator:layout:title", (message, data) => {
  store.commit("layout/SET_TITLE", data);
});

PubSub.subscribe("orchestrator:change:ym", (method, data = { met: "hit", target: "click" }) => {
  console.log("orchestrator:change:ym", data);
  if (data.met === "hit") {
    window[`yaCounter${process.env.VUE_APP_YANDEX_METRIC_ID}`][data.met]();
  } else {
    window[`yaCounter${process.env.VUE_APP_YANDEX_METRIC_ID}`][data.met](data.target);
  }
});

export default {
  name: "App",
  components: { IconRosAtom },
  computed: {
    ...mapGetters({
      token: "user/token",
      user: "user/user",
      applications: "applications/applications",
      bootstrap: "applications/bootstrap",
      registered: "applications/registered",
      openFirstApplication: "applications/openFirstApplication",
      menuOptions: "layout/menuOptions",
      isCompact: "layout/isCompact",
      title: "layout/title",
      subTitle: "layout/subTitle",
      headerTitle: "layout/headerTitle",
      headerRegion: "layout/headerRegion",
      breadcrumbs: "layout/breadcrumbs/breadcrumbs",
      pending: "layout/pending",
    }),
    logotype() {
      // eslint-disable-next-line global-require
      return require("@/static/logotype.svg");
    },
  },
  mounted() {
    setBootstrapMaxTime(20000, false, 60000);
    setMountMaxTime(20000, false, 60000);
    setUnmountMaxTime(20000, false, 60000);
    setUnloadMaxTime(20000, false, 60000);
    const registration = async () => {
      const config = await this.getConfig();
      this.$store.commit("applications/SET_APPLICATIONS", config.applications);
      this.$store.commit("applications/SET_OPEN_FIRST_APPLICATIONS", config.openFirstApplication);
      config.applications.forEach(({ name, src, route }) => {
        const modulesWrapper = document.querySelector("#modules");
        const moduleWrapper = document.createElement("div");
        moduleWrapper.setAttribute("id", `single-spa-application:${name}`);
        moduleWrapper.classList.add("single-spa-application");
        modulesWrapper.append(moduleWrapper);

        this.injection(src)
          .then(() => {
            const microFront = window[name];
            if (microFront) {
              registerApplication(name, microFront, (location) =>
                location.pathname.startsWith(route)
              );
              this.$store.commit("applications/SET_REGISTERED", { name, src, route });
            }
          })
          .catch(() => false);
      });
    };
    registration().then(() => {
      start();
    });
  },
  methods: {
    logout() {
      const token = getCookie("token");
      removeCookie("token");
      this.$store.commit("user/LOGOUT");
      window.location = `${VUE_APP_HOST}/portal/auth/logout?token=${token}`;
    },
    async getConfig() {
      const headers = {
        Authorization: `Bearer ${getCookie("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      if (process.env.NODE_ENV === "production") {
        const applications = await axios.get(`${VUE_APP_HOST}/api/user/applications`, { headers });
        return applications?.data;
      }
      const applications = await axios.get("/config.json");
      return applications?.data;
    },
    injection(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = `${src}?v=${Math.random()}`;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
  },
  watch: {
    bootstrap(n) {
      if (n.length === this.registered.length) {
        // const application = this.applications
        //   .find((item) => item.name === this.openFirstApplication);
        // if (this.openFirstApplication) {
        //   this.$router.replace({ path: application.route });
        // }
        this.$store.commit("layout/SET_PENDING", false);
      }
    },
  },
};
</script>

<style lang="scss">
.RLayoutDashboard {
  &__meta {
    min-height: 60px !important;
    & .RShorter {
      &:only-child {
        margin-top: 28px;
      }
    }
  }

  &__navigation {
    &:only-child {
      margin-bottom: 32px !important;
    }
  }

  &__title {
    //&:only-child {
    //  margin-top: 28px;
    //}
  }
}

.user-block {
  display: inline-flex;
  align-items: center;
  gap: 20px;

  &__popover {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}

.single-spa-pending {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-spa-pending,
.single-spa-application {
  height: calc(100vh - 92px);

  &:empty {
    height: 0;
  }
}
</style>
