import Vue from "vue";
// eslint-disable-next-line
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
// eslint-disable-next-line
import router from "./router";
import rir from "./plugins/RirLib";
import store from "./store";

const { VUE_APP_SENTRY_DSN, VUE_APP_SENTRY_ENVIRONMENT } = process.env;

Sentry.init({
  Vue,
  dsn: VUE_APP_SENTRY_DSN,
  environment: VUE_APP_SENTRY_ENVIRONMENT,
});

Vue.config.productionTip = false;

window.orchestrator = new Vue({
  router,
  store,
  $rir: rir,
  render: (h) => h(App),
  // mounted() {
  //   if (VUE_APP_YANDEX_METRIC_ENABLED) {
  //     YMetric.loadScript(() => {
  //       const ym = new YMetric(
  //         VUE_APP_YANDEX_METRIC_ID,
  //         {},
  //         VUE_APP_YANDEX_METRIC_DOMAINS ? VUE_APP_YANDEX_METRIC_DOMAINS?.split(",") : null
  //       );
  //       ym.createYM();
  //     });
  //   }
  // },
}).$mount("#orchestrator");
