<template>
  <div class="icon-ros-atom">
    <svg width="144" height="40" viewBox="0 0 144 40" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.0168 5.82388H55.2047C55.6542 5.82388 55.9939 5.73322 56.224 5.55188C56.4595 5.37055 56.5772 5.09322 56.5772 4.71988C56.5772 4.33588 56.4595 4.05855 56.224 3.88788C55.9939 3.71722 55.6542 3.63188 55.2047 3.63188H54.0168V5.82388ZM54.0168 8.55988H53.2944V2.95988H55.2689C55.5899 2.95988 55.8762 2.99455 56.1277 3.06388C56.3845 3.12788 56.6039 3.23188 56.7859 3.37588C56.9678 3.51988 57.1069 3.70122 57.2032 3.91988C57.2995 4.13855 57.3477 4.39988 57.3477 4.70388C57.3477 5.28522 57.1577 5.73055 56.7778 6.03988C56.4033 6.34388 55.8789 6.49588 55.2047 6.49588H54.0168V8.55988Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M59.6953 8.13588C59.8398 8.07188 59.9655 7.98122 60.0725 7.86388C60.1849 7.74122 60.2866 7.57855 60.3775 7.37588L60.4337 7.24788L58.3389 2.95988H59.1495L60.811 6.47188L62.1433 2.95988H62.8737L61.0357 7.49588C60.9929 7.60255 60.9394 7.71455 60.8752 7.83188C60.811 7.94388 60.7387 8.05322 60.6585 8.15988C60.5782 8.26122 60.4872 8.35455 60.3856 8.43988C60.2892 8.52522 60.1876 8.59188 60.0806 8.63988L59.6953 8.13588Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M67.9803 8.40788C67.8252 8.48255 67.6432 8.53855 67.4345 8.57588C67.2259 8.61855 66.9985 8.63988 66.7523 8.63988C66.3135 8.63988 65.9203 8.57588 65.5725 8.44788C65.23 8.31455 64.9384 8.12522 64.6976 7.87988C64.4568 7.63455 64.2722 7.34122 64.1438 6.99988C64.0154 6.65322 63.9512 6.26388 63.9512 5.83188C63.9512 5.35722 64.0154 4.93588 64.1438 4.56788C64.2722 4.19988 64.4568 3.89055 64.6976 3.63988C64.9384 3.38922 65.2273 3.19988 65.5644 3.07188C65.9069 2.94388 66.2868 2.87988 66.7042 2.87988C67.0252 2.87988 67.2847 2.90388 67.4827 2.95188C67.686 2.99455 67.8546 3.04255 67.9884 3.09588L67.8519 3.72788C67.6753 3.66388 67.4961 3.61855 67.3142 3.59188C67.1322 3.56522 66.953 3.55188 66.7764 3.55188C66.1075 3.55188 65.5965 3.73855 65.2434 4.11188C64.8956 4.48522 64.7217 5.03455 64.7217 5.75988C64.7217 6.10655 64.7698 6.41855 64.8661 6.69588C64.9678 6.96788 65.1096 7.19988 65.2915 7.39188C65.4735 7.57855 65.6955 7.72255 65.9577 7.82388C66.2199 7.91988 66.5169 7.96788 66.8486 7.96788C67.0252 7.96788 67.2018 7.95188 67.3784 7.91988C67.5603 7.88788 67.7208 7.83722 67.8599 7.76788L67.9803 8.40788Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M72.8668 7.06388H70.467L69.9212 8.55988H69.1908L71.3338 2.95988H72.0562L74.159 8.55988H73.4046L72.8668 7.06388ZM70.6997 6.40788H72.6341L71.695 3.77588H71.6629L70.6997 6.40788Z"
        fill="var(--rir-troy)"></path>
      <path d="M76.9431 8.55988H76.2207V3.63188H74.5961V2.95988H78.4808V3.63188H76.9431V8.55988Z"
            fill="var(--rir-troy)"></path>
      <path
        d="M82.2678 7.96788C82.7975 7.96788 83.2122 7.78388 83.5119 7.41588C83.8169 7.04255 83.9694 6.49055 83.9694 5.75988C83.9694 5.37588 83.9266 5.04522 83.841 4.76788C83.7607 4.49055 83.643 4.26122 83.4878 4.07988C83.3326 3.89855 83.1427 3.76522 82.9179 3.67988C82.6985 3.59455 82.4497 3.55188 82.1715 3.55188C81.9254 3.55188 81.6953 3.59722 81.4812 3.68788C81.2672 3.77855 81.0826 3.91455 80.9274 4.09588C80.7722 4.27722 80.6492 4.50655 80.5582 4.78388C80.4672 5.06122 80.4218 5.38655 80.4218 5.75988C80.4218 6.12788 80.4646 6.45055 80.5502 6.72788C80.6412 7.00522 80.7669 7.23722 80.9274 7.42388C81.0879 7.60522 81.2806 7.74122 81.5053 7.83188C81.7354 7.92255 81.9896 7.96788 82.2678 7.96788ZM82.1956 8.63988C81.805 8.63988 81.4518 8.57322 81.1361 8.43988C80.8258 8.30655 80.5582 8.11722 80.3335 7.87188C80.1141 7.62122 79.9455 7.31722 79.8278 6.95988C79.7101 6.60255 79.6512 6.20255 79.6512 5.75988C79.6512 5.31188 79.7128 4.91188 79.8359 4.55988C79.9589 4.20255 80.1301 3.90122 80.3495 3.65588C80.5743 3.40522 80.8418 3.21322 81.1522 3.07988C81.4625 2.94655 81.8023 2.87988 82.1715 2.87988C82.5889 2.87988 82.9554 2.95188 83.2711 3.09588C83.5921 3.23455 83.8597 3.43188 84.0737 3.68788C84.2931 3.93855 84.459 4.24255 84.5713 4.59988C84.6837 4.95188 84.7399 5.33855 84.7399 5.75988C84.7399 6.23455 84.6757 6.65322 84.5473 7.01588C84.4188 7.37322 84.2423 7.67188 84.0175 7.91188C83.7928 8.15188 83.5252 8.33322 83.2149 8.45588C82.9046 8.57855 82.5648 8.63988 82.1956 8.63988Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M91.9949 8.55988H91.2725L90.9274 4.18388L89.5067 8.55988H88.7523L87.3396 4.19988L86.9865 8.55988H86.2641L86.7457 2.95988H87.6286L89.1455 7.77588L90.6304 2.95988H91.5133L91.9949 8.55988Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M57.5644 17.1999H56.842V12.6879L54.1131 17.1999H53.2944V11.5999H54.0168V16.1119L56.7457 11.5999H57.5644V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M63.8563 17.1999H63.1339V14.6239H60.2926V17.1999H59.5703V11.5999H60.2926V13.9679H63.1339V11.5999H63.8563V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M68.7753 15.9839C69.3264 15.9626 69.7545 15.8212 70.0595 15.5599C70.3645 15.2932 70.517 14.9012 70.517 14.3839C70.517 13.8559 70.3645 13.4639 70.0595 13.2079C69.7599 12.9466 69.3318 12.8052 68.7753 12.7839V15.9839ZM68.0529 12.7839C67.5072 12.8052 67.0791 12.9492 66.7687 13.2159C66.4637 13.4825 66.3113 13.8719 66.3113 14.3839C66.3113 14.8852 66.4664 15.2719 66.7768 15.5439C67.0871 15.8159 67.5125 15.9626 68.0529 15.9839V12.7839ZM68.7753 17.2799H68.0529V16.6399C67.6944 16.6292 67.3627 16.5759 67.0577 16.4799C66.7527 16.3786 66.4878 16.2346 66.2631 16.0479C66.0384 15.8612 65.8618 15.6292 65.7334 15.3519C65.6049 15.0746 65.5407 14.7519 65.5407 14.3839C65.5407 14.0319 65.6049 13.7199 65.7334 13.4479C65.8671 13.1706 66.0491 12.9359 66.2791 12.7439C66.5092 12.5519 66.7741 12.4052 67.0737 12.3039C67.3787 12.1972 67.7051 12.1386 68.0529 12.1279V11.5039H68.7753V12.1279C69.1338 12.1386 69.4656 12.1945 69.7706 12.2959C70.0809 12.3919 70.3485 12.5359 70.5732 12.7279C70.7979 12.9146 70.9718 13.1466 71.0949 13.4239C71.2233 13.7012 71.2875 14.0212 71.2875 14.3839C71.2875 14.7466 71.2233 15.0666 71.0949 15.3439C70.9665 15.6212 70.7899 15.8559 70.5652 16.0479C70.3404 16.2346 70.0729 16.3786 69.7625 16.4799C69.4575 16.5759 69.1285 16.6292 68.7753 16.6399V17.2799Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M73.6987 14.4639H74.8865C75.336 14.4639 75.6758 14.3732 75.9059 14.1919C76.1413 14.0106 76.259 13.7332 76.259 13.3599C76.259 12.9759 76.1413 12.6986 75.9059 12.5279C75.6758 12.3572 75.336 12.2719 74.8865 12.2719H73.6987V14.4639ZM73.6987 17.1999H72.9763V11.5999H74.9508C75.2718 11.5999 75.5581 11.6346 75.8096 11.7039C76.0664 11.7679 76.2858 11.8719 76.4677 12.0159C76.6496 12.1599 76.7888 12.3412 76.8851 12.5599C76.9814 12.7786 77.0296 13.0399 77.0296 13.3439C77.0296 13.9252 76.8396 14.3706 76.4597 14.6799C76.0851 14.9839 75.5607 15.1359 74.8865 15.1359H73.6987V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M81.306 15.7039H78.9061L78.3603 17.1999H77.63L79.773 11.5999H80.4953L82.5982 17.1999H81.8437L81.306 15.7039ZM79.1389 15.0479H81.0732L80.1341 12.4159H80.102L79.1389 15.0479Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M87.67 17.0479C87.5149 17.1226 87.3329 17.1786 87.1242 17.2159C86.9156 17.2586 86.6882 17.2799 86.442 17.2799C86.0033 17.2799 85.61 17.2159 85.2622 17.0879C84.9197 16.9546 84.6281 16.7652 84.3873 16.5199C84.1465 16.2746 83.9619 15.9812 83.8335 15.6399C83.7051 15.2932 83.6409 14.9039 83.6409 14.4719C83.6409 13.9972 83.7051 13.5759 83.8335 13.2079C83.9619 12.8399 84.1465 12.5306 84.3873 12.2799C84.6281 12.0292 84.917 11.8399 85.2541 11.7119C85.5966 11.5839 85.9765 11.5199 86.3939 11.5199C86.7149 11.5199 86.9744 11.5439 87.1724 11.5919C87.3757 11.6346 87.5443 11.6826 87.6781 11.7359L87.5416 12.3679C87.365 12.3039 87.1858 12.2586 87.0039 12.2319C86.8219 12.2052 86.6427 12.1919 86.4661 12.1919C85.7972 12.1919 85.2862 12.3786 84.9331 12.7519C84.5853 13.1252 84.4114 13.6746 84.4114 14.3999C84.4114 14.7466 84.4595 15.0585 84.5558 15.3359C84.6575 15.6079 84.7993 15.8399 84.9812 16.0319C85.1632 16.2186 85.3852 16.3626 85.6474 16.4639C85.9096 16.5599 86.2066 16.6079 86.5383 16.6079C86.7149 16.6079 86.8915 16.5919 87.0681 16.5599C87.25 16.5279 87.4105 16.4772 87.5496 16.4079L87.67 17.0479Z"
        fill="var(--rir-troy)"></path>
      <path d="M91.2662 17.1999H90.5438V12.2719H88.8507V11.5999H92.9601V12.2719H91.2662V17.1999Z"
            fill="var(--rir-troy)"></path>
      <path
        d="M95.0942 14.4639H96.2821C96.7316 14.4639 97.0714 14.3732 97.3015 14.1919C97.5369 14.0106 97.6546 13.7332 97.6546 13.3599C97.6546 12.9759 97.5369 12.6986 97.3015 12.5279C97.0714 12.3572 96.7316 12.2719 96.2821 12.2719H95.0942V14.4639ZM95.0942 17.1999H94.3719V11.5999H96.3463C96.6674 11.5999 96.9537 11.6346 97.2052 11.7039C97.462 11.7679 97.6814 11.8719 97.8633 12.0159C98.0452 12.1599 98.1844 12.3412 98.2807 12.5599C98.377 12.7786 98.4251 13.0399 98.4251 13.3439C98.4251 13.9252 98.2352 14.3706 97.8553 14.6799C97.4807 14.9839 96.9563 15.1359 96.2821 15.1359H95.0942V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M100.773 16.7759C100.917 16.7119 101.043 16.6212 101.15 16.5039C101.262 16.3812 101.364 16.2185 101.455 16.0159L101.511 15.8879L99.4163 11.5999H100.227L101.888 15.1119L103.221 11.5999H103.951L102.113 16.1359C102.07 16.2425 102.017 16.3546 101.953 16.4719C101.888 16.5839 101.816 16.6932 101.736 16.7999C101.656 16.9012 101.565 16.9946 101.463 17.0799C101.367 17.1652 101.265 17.2319 101.158 17.2799L100.773 16.7759Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M108.544 17.1999C108.298 16.8426 108.092 16.5332 107.926 16.2719C107.766 16.0106 107.629 15.7866 107.517 15.5999C107.404 15.4079 107.311 15.2532 107.236 15.1359C107.161 15.0132 107.089 14.9199 107.019 14.8559C106.955 14.7865 106.883 14.7386 106.802 14.7119C106.727 14.6852 106.634 14.6719 106.521 14.6719H105.992V17.1999H105.269V11.5999H105.992V14.1439H106.465L108.448 11.5999H109.371L107.212 14.1759C107.367 14.2025 107.503 14.2799 107.621 14.4079C107.739 14.5306 107.873 14.7146 108.022 14.9599C108.172 15.1999 108.357 15.5066 108.576 15.8799C108.801 16.2479 109.095 16.6879 109.459 17.1999H108.544Z"
        fill="var(--rir-troy)"></path>
      <path d="M112.677 17.1999H111.955V12.2719H110.233V11.5999H114.374V12.2719H112.677V17.1999Z"
            fill="var(--rir-troy)"></path>
      <path
        d="M116.771 16.7759C116.915 16.7119 117.041 16.6212 117.148 16.5039C117.26 16.3812 117.362 16.2185 117.453 16.0159L117.509 15.8879L115.414 11.5999H116.225L117.886 15.1119L119.219 11.5999H119.949L118.111 16.1359C118.068 16.2425 118.015 16.3546 117.951 16.4719C117.886 16.5839 117.814 16.6932 117.734 16.7999C117.654 16.9012 117.563 16.9946 117.461 17.0799C117.365 17.1652 117.263 17.2319 117.156 17.2799L116.771 16.7759Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M121.99 14.4639H123.178C123.627 14.4639 123.967 14.3732 124.197 14.1919C124.432 14.0106 124.55 13.7332 124.55 13.3599C124.55 12.9759 124.432 12.6986 124.197 12.5279C123.967 12.3572 123.627 12.2719 123.178 12.2719H121.99V14.4639ZM121.99 17.1999H121.267V11.5999H123.242C123.563 11.5999 123.849 11.6346 124.101 11.7039C124.357 11.7679 124.577 11.8719 124.759 12.0159C124.941 12.1599 125.08 12.3412 125.176 12.5599C125.272 12.7786 125.321 13.0399 125.321 13.3439C125.321 13.9252 125.131 14.3706 124.751 14.6799C124.376 14.9839 123.852 15.1359 123.178 15.1359H121.99V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M131.21 17.1999H130.487V14.6239H127.646V17.1999H126.924V11.5999H127.646V13.9679H130.487V11.5999H131.21V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M133.938 16.5279H134.411C135.278 16.5279 135.669 16.4319 135.904 16.2399C136.145 16.0479 136.265 15.7706 136.265 15.4079C136.265 15.0399 136.142 14.7786 135.896 14.6239C135.65 14.4639 135.267 14.3839 134.748 14.3839H133.938V16.5279ZM133.215 17.1999V11.5999H133.938V13.7119H134.772C135.147 13.7119 135.473 13.7439 135.751 13.8079C136.035 13.8666 136.27 13.9652 136.458 14.1039C136.65 14.2372 136.795 14.4106 136.891 14.6239C136.987 14.8372 137.036 15.0986 137.036 15.4079C137.036 15.7386 136.977 16.0186 136.859 16.2479C136.747 16.4719 136.589 16.6559 136.386 16.7999C136.182 16.9386 135.939 17.0399 135.655 17.1039C135.377 17.1679 135.072 17.1999 134.419 17.1999H133.215ZM138.858 17.1999H138.135V11.5999H138.858V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M144 17.1999H140.862V11.5999H144V12.2719H141.584V13.9679H143.792V14.6239H141.584V16.5279H144V17.1999Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M54.0168 23.1039H55.2047C55.6542 23.1039 55.9939 23.0132 56.224 22.8319C56.4595 22.6506 56.5772 22.3732 56.5772 21.9999C56.5772 21.6159 56.4595 21.3386 56.224 21.1679C55.9939 20.9972 55.6542 20.9119 55.2047 20.9119H54.0168V23.1039ZM54.0168 25.8399H53.2944V20.2399H55.2689C55.5899 20.2399 55.8762 20.2746 56.1277 20.3439C56.3845 20.4079 56.6039 20.5119 56.7859 20.6559C56.9678 20.7999 57.1069 20.9812 57.2032 21.1999C57.2995 21.4186 57.3477 21.6799 57.3477 21.9839C57.3477 22.5652 57.1577 23.0106 56.7778 23.3199C56.4033 23.6239 55.8789 23.7759 55.2047 23.7759H54.0168V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M62.4101 25.8399H58.9507V20.2399H62.4101V20.9119H59.6731V22.6079H61.8803V23.2639H59.6731V25.1679H62.4101V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M69.7212 25.8399H63.8941V20.2399H64.6165V25.1679H66.4464V20.2399H67.1688V25.1679H68.9988V20.2399H69.7212V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M75.189 25.8399H71.7297V20.2399H75.189V20.9119H72.4521V22.6079H74.6593V23.2639H72.4521V25.1679H75.189V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M80.9591 25.8399H80.2367V23.2639H77.3954V25.8399H76.6731V20.2399H77.3954V22.6079H80.2367V20.2399H80.9591V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M87.2345 25.8399H86.5122V21.3279L83.7833 25.8399H82.9646V20.2399H83.6869V24.7519L86.4159 20.2399H87.2345V25.8399Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M91.2309 23.5999C91.0811 23.5999 90.958 23.6132 90.8617 23.6399C90.7654 23.6666 90.6798 23.7146 90.6049 23.7839C90.5353 23.8479 90.4711 23.9359 90.4123 24.0479C90.3534 24.1546 90.2865 24.2932 90.2116 24.4639C90.1367 24.6292 90.0484 24.8266 89.9467 25.0559C89.8504 25.2799 89.7247 25.5412 89.5695 25.8399H88.7588C88.914 25.5466 89.0531 25.2612 89.1762 24.9839C89.2993 24.7066 89.4116 24.4559 89.5133 24.2319C89.6203 24.0026 89.7193 23.8106 89.8103 23.6559C89.9066 23.5012 90.0056 23.3999 90.1073 23.3519C89.9949 23.3199 89.8772 23.2666 89.7541 23.1919C89.631 23.1172 89.5187 23.0239 89.417 22.9119C89.3153 22.7945 89.2324 22.6532 89.1682 22.4879C89.104 22.3226 89.0719 22.1306 89.0719 21.9119C89.0719 21.6132 89.1174 21.3599 89.2083 21.1519C89.3046 20.9386 89.4411 20.7652 89.6177 20.6319C89.7942 20.4932 90.0083 20.3946 90.2598 20.3359C90.5112 20.2719 90.7948 20.2399 91.1105 20.2399H92.6837V25.8399H91.9613V23.5999H91.2309ZM91.9613 22.9279V20.9119H90.934C90.6129 20.9119 90.3507 20.9972 90.1474 21.1679C89.9441 21.3386 89.8424 21.5919 89.8424 21.9279C89.8424 22.0986 89.8745 22.2479 89.9387 22.3759C90.0029 22.4986 90.0912 22.6026 90.2036 22.6879C90.3213 22.7679 90.4604 22.8292 90.6209 22.8719C90.7815 22.9092 90.9554 22.9279 91.1426 22.9279H91.9613Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M53.9075 34.4533H54.9177C55.2992 34.4533 55.5876 34.3778 55.7828 34.2267C55.9827 34.0756 56.0826 33.8444 56.0826 33.5333C56.0826 33.2133 55.9827 32.9822 55.7828 32.84C55.5876 32.6978 55.2992 32.6267 54.9177 32.6267H53.9075V34.4533ZM53.9075 36.7333H53.2944V32.0667H54.9722C55.2447 32.0667 55.4876 32.0956 55.7011 32.1533C55.9191 32.2067 56.1053 32.2933 56.2597 32.4133C56.4141 32.5333 56.5322 32.6844 56.6139 32.8667C56.6957 33.0489 56.7366 33.2667 56.7366 33.52C56.7366 34.0044 56.5753 34.3756 56.2529 34.6333C55.935 34.8867 55.4899 35.0133 54.9177 35.0133H53.9075V36.7333Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M60.1881 36.24C60.6377 36.24 60.9896 36.0867 61.244 35.78C61.5028 35.4689 61.6323 35.0089 61.6323 34.4C61.6323 34.08 61.5959 33.8044 61.5233 33.5733C61.4551 33.3422 61.3552 33.1511 61.2235 33C61.0918 32.8489 60.9306 32.7378 60.7399 32.6667C60.5536 32.5956 60.3425 32.56 60.1063 32.56C59.8974 32.56 59.7021 32.5978 59.5205 32.6733C59.3388 32.7489 59.1821 32.8622 59.0504 33.0133C58.9187 33.1644 58.8143 33.3556 58.7371 33.5867C58.6598 33.8178 58.6212 34.0889 58.6212 34.4C58.6212 34.7067 58.6576 34.9756 58.7302 35.2067C58.8074 35.4378 58.9142 35.6311 59.0504 35.7867C59.1867 35.9378 59.3502 36.0511 59.5409 36.1267C59.7362 36.2022 59.9519 36.24 60.1881 36.24ZM60.1267 36.8C59.7952 36.8 59.4955 36.7444 59.2275 36.6333C58.9641 36.5222 58.7371 36.3644 58.5463 36.16C58.3601 35.9511 58.2171 35.6978 58.1171 35.4C58.0172 35.1022 57.9673 34.7689 57.9673 34.4C57.9673 34.0267 58.0195 33.6933 58.124 33.4C58.2284 33.1022 58.3737 32.8511 58.5599 32.6467C58.7507 32.4378 58.9778 32.2778 59.2412 32.1667C59.5046 32.0556 59.7929 32 60.1063 32C60.4605 32 60.7716 32.06 61.0396 32.18C61.3121 32.2956 61.5392 32.46 61.7208 32.6733C61.907 32.8822 62.0478 33.1356 62.1432 33.4333C62.2385 33.7267 62.2862 34.0489 62.2862 34.4C62.2862 34.7956 62.2317 35.1444 62.1227 35.4467C62.0137 35.7444 61.8639 35.9933 61.6731 36.1933C61.4824 36.3933 61.2553 36.5444 60.9919 36.6467C60.7285 36.7489 60.4401 36.8 60.1267 36.8Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M67.0094 36.6067C66.8777 36.6689 66.7233 36.7156 66.5462 36.7467C66.369 36.7822 66.176 36.8 65.9671 36.8C65.5947 36.8 65.2609 36.7467 64.9657 36.64C64.6751 36.5289 64.4276 36.3711 64.2232 36.1667C64.0188 35.9622 63.8621 35.7178 63.7531 35.4333C63.6441 35.1444 63.5896 34.82 63.5896 34.46C63.5896 34.0644 63.6441 33.7133 63.7531 33.4067C63.8621 33.1 64.0188 32.8422 64.2232 32.6333C64.4276 32.4244 64.6728 32.2667 64.9589 32.16C65.2496 32.0533 65.572 32 65.9262 32C66.1987 32 66.419 32.02 66.587 32.06C66.7596 32.0956 66.9027 32.1356 67.0162 32.18L66.9004 32.7067C66.7505 32.6533 66.5984 32.6156 66.444 32.5933C66.2896 32.5711 66.1374 32.56 65.9876 32.56C65.4199 32.56 64.9862 32.7156 64.6864 33.0267C64.3912 33.3378 64.2436 33.7956 64.2436 34.4C64.2436 34.6889 64.2845 34.9489 64.3662 35.18C64.4525 35.4067 64.5729 35.6 64.7273 35.76C64.8817 35.9156 65.0702 36.0356 65.2927 36.12C65.5152 36.2 65.7673 36.24 66.0489 36.24C66.1987 36.24 66.3486 36.2267 66.4985 36.2C66.6529 36.1733 66.7891 36.1311 66.9072 36.0733L67.0094 36.6067Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M71.3611 35.4867H69.3243L68.861 36.7333H68.2411L70.06 32.0667H70.6731L72.4579 36.7333H71.8176L71.3611 35.4867ZM69.5218 34.94H71.1636L70.3665 32.7467H70.3393L69.5218 34.94Z"
        fill="var(--rir-troy)"></path>
      <path d="M75.2996 36.7333H74.6865V32.6267H73.149V32.0667H76.8538V32.6267H75.2996V36.7333Z"
            fill="var(--rir-troy)"></path>
      <path
        d="M80.0233 36.24C80.4729 36.24 80.8249 36.0867 81.0792 35.78C81.3381 35.4689 81.4675 35.0089 81.4675 34.4C81.4675 34.08 81.4312 33.8044 81.3585 33.5733C81.2904 33.3422 81.1905 33.1511 81.0588 33C80.9271 32.8489 80.7659 32.7378 80.5751 32.6667C80.3889 32.5956 80.1777 32.56 79.9416 32.56C79.7327 32.56 79.5374 32.5978 79.3557 32.6733C79.1741 32.7489 79.0174 32.8622 78.8857 33.0133C78.754 33.1644 78.6495 33.3556 78.5723 33.5867C78.4951 33.8178 78.4565 34.0889 78.4565 34.4C78.4565 34.7067 78.4929 34.9756 78.5655 35.2067C78.6427 35.4378 78.7495 35.6311 78.8857 35.7867C79.0219 35.9378 79.1854 36.0511 79.3762 36.1267C79.5715 36.2022 79.7872 36.24 80.0233 36.24ZM79.962 36.8C79.6305 36.8 79.3308 36.7444 79.0628 36.6333C78.7994 36.5222 78.5723 36.3644 78.3816 36.16C78.1954 35.9511 78.0523 35.6978 77.9524 35.4C77.8525 35.1022 77.8025 34.7689 77.8025 34.4C77.8025 34.0267 77.8548 33.6933 77.9592 33.4C78.0637 33.1022 78.209 32.8511 78.3952 32.6467C78.586 32.4378 78.813 32.2778 79.0764 32.1667C79.3398 32.0556 79.6282 32 79.9416 32C80.2958 32 80.6069 32.06 80.8749 32.18C81.1474 32.2956 81.3744 32.46 81.5561 32.6733C81.7423 32.8822 81.8831 33.1356 81.9785 33.4333C82.0738 33.7267 82.1215 34.0489 82.1215 34.4C82.1215 34.7956 82.067 35.1444 81.958 35.4467C81.849 35.7444 81.6992 35.9933 81.5084 36.1933C81.3177 36.3933 81.0906 36.5444 80.8272 36.6467C80.5638 36.7489 80.2754 36.8 79.962 36.8Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M88.2889 36.7333H87.6758L87.3828 33.0867L86.1771 36.7333H85.5367L84.3378 33.1L84.038 36.7333H83.4249L83.8337 32.0667H84.583L85.8705 36.08L87.1308 32.0667H87.8801L88.2889 36.7333Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M23.2222 22.9315C22.6653 23.5414 21.9396 23.9732 21.1367 24.1725C20.3338 24.3718 19.4897 24.3296 18.7108 24.0513C17.932 23.773 17.2532 23.271 16.7603 22.6087C16.2673 21.9463 15.9822 21.1533 15.9408 20.3296C15.8995 19.5059 16.1039 18.6885 16.5281 17.9804C16.9523 17.2724 17.5774 16.7054 18.3245 16.351C19.0717 15.9967 19.9074 15.8708 20.7262 15.9892C21.545 16.1077 22.3103 16.4652 22.9255 17.0167C23.3361 17.3847 23.6696 17.8301 23.9069 18.3271C24.1442 18.8241 24.2805 19.3629 24.3081 19.9126C24.3357 20.4623 24.2539 21.012 24.0676 21.5301C23.8812 22.0482 23.5939 22.5245 23.2222 22.9315Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M30.3957 28.4344C28.8281 28.7032 27.5773 28.768 25.7314 28.4773C23.042 31.5422 22.0418 35.6713 23.2608 39.6187C28.5955 38.7124 33.4563 35.6534 36.5303 31.198C39.257 27.246 40.4204 23.3598 40.0707 18.5159C39.0689 23.5934 36.0744 27.4605 30.3957 28.4344Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M35.9326 8.03546C33.2122 11.1356 29.1316 12.492 24.4682 11.7169C24.3711 12.492 24.1701 13.9775 26.6783 16.6752C29.8563 20.0929 32.0359 23.4591 31.5859 27.0995C31.5859 27.0995 38.264 26.1435 39.5916 16.7332C39.5916 16.7332 39.5292 12.7177 35.9326 8.03546Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M22.1131 6.75975C23.1305 7.97912 23.8121 9.02685 24.4826 10.7663C28.4891 11.5563 32.5753 10.3556 35.394 7.32909C31.9396 3.17534 26.8525 0.507238 21.446 0.0803208C16.6504 -0.298359 12.6936 0.63995 8.66164 3.36396C13.5723 1.69026 18.428 2.3427 22.1131 6.75975Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M1.62865 12.1777C5.68128 12.9768 8.89953 15.8225 10.5581 20.2373C11.2798 19.9336 12.6705 19.3644 13.7592 15.8495C15.1385 11.3962 16.9722 7.83086 20.3588 6.3993C20.3588 6.3993 16.1895 1.11032 7.35304 4.66894C7.35304 4.66894 3.89675 6.73063 1.62865 12.1777Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M7.40469 24.8415C7.95504 23.3533 8.52416 22.2408 9.69956 20.792C8.38243 16.9371 5.29654 14.0088 1.25872 13.0878C-0.621529 18.1478 -0.395209 23.8749 1.93726 28.7573C4.00619 33.088 6.79949 36.0358 11.1812 38.1557C7.27236 34.7519 5.41111 30.2324 7.40469 24.8415Z"
        fill="var(--rir-troy)"></path>
      <path
        d="M22.3524 39.8225C21.0201 35.9231 21.8824 31.7211 24.8873 28.0814C24.2627 27.61 23.073 26.6937 19.476 27.5109C14.9188 28.5464 10.9055 28.7456 7.96887 26.5368C7.96887 26.5368 5.46012 32.7818 12.969 38.6334C12.969 38.6334 16.4876 40.5873 22.3524 39.8225Z"
        fill="var(--rir-troy)"></path>
      <rect x="46.2312" y="3.19995" width="0.32105" height="33.92" fill="var(--rir-troy)"></rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconRosAtom',
};
</script>

<style>
.icon-ros-atom {
  height: 40px;
  overflow: hidden;
}
</style>
