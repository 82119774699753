import axios from 'axios';
import { getCookie } from '@/services/cookies';
import rirLib from '@/plugins/RirLib';
import { DEFAULT_NOTIFICATIONS_COUNTDOWN_SEC } from '@/aliaces';

const { framework } = rirLib;
const { VUE_APP_HOST } = process.env;

const API = axios.create({
  baseURL: VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getCookie('token') ? `Bearer ${getCookie('token')}` : null,
  },
});

// Add a request interceptor
API.interceptors.request.use(
  (config) => config,
  // Do something before request is sent
  (error) => {
    // Do something with request error
    framework.notification.send({ title: error });
    return Promise.reject(error);
  },
);

// Add a request interceptor
API.interceptors.request.use(
  (config) => config,
  // Do something before request is sent
  (error) => {
  // Do something with request error
    framework.notification.send({ title: error });
    return Promise.reject(error);
  },
);

// Add a response interceptor
API.interceptors.response.use(
  (response) => response,
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
    console.log(error);
    const props = () => {
      if (error.response.status >= 400 && error.response.status < 500) {
        return {
          title: error?.response?.data?.message || error?.message,
          seconds: DEFAULT_NOTIFICATIONS_COUNTDOWN_SEC,
          buttonActionOptions: null,
        };
      } if (error.response.status >= 500) {
        return {
          title: error?.response?.data?.message || error?.message,
          iconOptions: {
            fill: 'fargo',
            icon: 'block',
          },
          buttonActionOptions: {
            title: 'Понятно',
            clickEvent: () => null,
          },
        };
      }
      return null;
    };
    framework.notification.send(props());
    return Promise.reject(error);
  },
);

export default API;
